var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "flex flex-col items-center justify-center pt-4" },
    [
      _c("icon-component", {
        staticClass: "animate-pulse",
        attrs: { name: "airForce", width: 120, height: 80 },
      }),
      _c(
        "h1",
        { staticClass: "justify-center pt-4 mb-4 text-xl overflow-ellipsis" },
        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
      ),
      _c(
        "h1",
        { staticClass: "justify-center mb-4 text-xl overflow-ellipsis" },
        [_vm._v(" " + _vm._s(_vm.message) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }