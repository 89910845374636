<template lang="html">
  <section class="flex flex-col items-center justify-center pt-4">
    <icon-component
      name="airForce"
      :width="120"
      :height="80"
      class="animate-pulse"
    />
    <h1 class="justify-center pt-4 mb-4 text-xl overflow-ellipsis">
      {{ errorMessage }}
    </h1>
    <h1 class="justify-center mb-4 text-xl overflow-ellipsis">
      {{ message }}
    </h1>
  </section>
</template>
<script>
import Routes from '@/router/Routes';
import IconComponent from '@/components/IconComponent.vue';
export default {
  name: 'invite',
  components: {
    IconComponent
  },
  data() {
    return {
      message: 'Please wait, you will be redirected to the home page.',
      errorMessage: '',
      pulsateLogo: true
    };
  },
  async mounted() {
    const response = await this.$store.dispatch(
      'userModule/processInvitation',
      this.$router.currentRoute.params.hash
    );
    if (response.status >= 400) {
      this.message = 'please contact the person who sent you this invitation';
      this.errorMessage = 'Error: ' + response.message;
      this.pulsateLogo = false;
    } else {
      await this.$store.dispatch('userModule/setUser', response);
      this.$router.push({ name: Routes.HOME });
    }
  }
};
</script>
